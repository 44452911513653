<template>
    <o-data-lookup :data-object="dsWorkflows" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                <slot name="workflow"></slot>
            </span>
        </template>
        <o-column field="ID" width="80" v-slot="{ row }">
            <span :class="{ 'text-decoration-line-through': row.Closed != null }">{{ row.ID }}</span>
        </o-column>
        <o-column field="Title" width="400"></o-column>
        <o-column field="OrgUnit" width="230"></o-column>
        <o-column field="CreatedBy" width="250"></o-column>
    </o-data-lookup>
</template>

<script setup>    
import { defineProps } from "vue";

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,
});

const dsWorkflows = $getDataObjectById("dsWorkflow_Workflows");
// cache
</script>